<template>
  <div class="home-box">

    <!-- 顶部导航 -->
    <top-nav currTab="0" v-model:banner="banner" height="800px">
      <!-- banner描述 -->
      <div class="banner-content">
        <p class="line-0 FONT_SHSR">价值观</p>
        <p class="line-1 FONT_SHSB">为您服务是我们认为做的正确的事情。</p>
      </div>
    </top-nav>

    <!-- 描述 -->
    <div class="content-box">

      <!-- 标题 -->
      <div class="content-title">
        <!-- 标题 -->
        <div class="title-text FONT_SHSB">
          深圳市方合科技有限公司
        </div>

        <!-- 内容 -->
        <div class="title-content FONT_SHSR">
          深圳方合科技有限公司成立于2014年，早期从事锂电池新能源材料方面的业务，经营区域覆盖到全国，2019年6月转型线上服务平台，为新能源材料企业提供线上信息咨询服务，旗下产品有工解等。
        </div>
      </div>

      <!-- 内容 -->
      <div class="view-content">

        <!-- 项 -->
        <div class="view-line icon-left line-icon">

          <!-- 左侧 -->
          <div class="left text-content">
            <p class="text-title FONT_SHSB">企业文化</p>
            <p class="text FONT_SHSR">节俭与务实是方合科技企业文化的重要组成部分。员工之间以及部门之间的尊重与协助也是方合科技企业文化的重要组成部分。</p>
          </div>

          <!-- 右侧 -->
          <div class="right preview">
            <img src="../assets/images/image-0.png" alt="">
          </div> 

        </div>

        <!-- 项 -->
        <div class="view-line icon-right line-icon">

          <!-- 左侧 -->
          <div class="left preview">
            <img src="../assets/images/image-1.png" alt="">
          </div> 

          <!-- 右侧 -->
          <div class="right text-content">
            <p class="text-title FONT_SHSB">工解</p>
            <p class="text FONT_SHSR">工解是一个服务新能源相关材料的信息和服务平台，涵盖内容、咨询和社区，致力于为新能源相关企业和人员提供专业方面的质询内容和服务。服务宗旨是为企业和个人提供有价值的专业性的信息和咨询。</p>
          </div>
        </div>

      </div>

    </div>

    <bottom-nav></bottom-nav>

  </div>
</template>

<script>
import topNav from '@/components/topNav'
import bottomNav from '@/components/bottomNav'

import {ref} from 'vue'
export default {
  components:{
    topNav,
    bottomNav
  },
  setup(){
    
		// banner 
		const banner = require('@/assets/images/banner.png')

		return {
			banner
		};

  }
}
</script>

<style lang="scss" scoped>

  .WP_{
    width: 1300px;
    padding: 90px calc(50% - 650px);
  }

  .home-box{
    // 顶部信息
    .banner-content{
      height: 150px;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: flex-start;
      justify-content: center;
      align-content: flex-start;
      width: 320px;
      padding: 30px 15px;
      background-color: white;
      top: 62px;
      position: absolute;
      left: 18%;
      padding-bottom: 40px;
      border-radius: 0 0 100px 0;

      .line-0{
        color:#6A6A6A;
        font-size: 26px;
        margin-bottom: 15px;
      }
      .line-1{
        font-size: 30px;
      }
    }

    // 描述
    .content-box{
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: center;
      margin: 50px auto;
      width: 100%;

      // 标题
      .content-title{
        background-image: url(../assets/images/title.png);
			  background-position: center 70px;
        background-size: cover;
        background-repeat: no-repeat;

        @extend .WP_;
        // width: 1300px;
        // padding: 90px calc(50% - 650px);

        .title-text{
          font-size: 50px;
          text-align: center;
        }

        .title-content{
          font-size: 20px;
          color: #6A6A6A;
          margin: 100px 0;
          line-height: 30px;
          margin-bottom: 50px;
        }
      }

      .view-content{
        width: 100%;

        .view-line{
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: space-between;
          align-items: center;
          
          @extend .WP_;
          // width: 1300px;
          // padding: 90px calc(50% - 650px);

          &.line-icon{
            background-size: contain;
            background-repeat: no-repeat;
          }
          &.icon-left{
            background-image: url(../assets/images/right.png);
            background-position: left;
          }
          &.icon-right{
            background-image: url(../assets/images/left.png);
            background-position: right;
          }

          .text-content{
            width: 40%;

            .text-title{
              font-size: 55px;
              line-height: 100px;
            }
            .text{
              color: #6A6A6A;
              font-size: 20px;
            }
          }
          .left.text-content{
            text-align: left;
          }
          .right.text-content{
            text-align: right;
          }
        }
      }

    }

  }
</style>